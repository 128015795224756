<template>
    <div class="page" style="min-height: 100vh;font-size: 18px;">
        <van-nav-bar class="one-nav" :border="false" :placeholder="true" :fixed="true" :left-text="$t('下载并安装')" left-arrow
                     @click-left="onClickLeft">
        </van-nav-bar>
        <div class="wrapper">
            <div class="bs-panel one-card">
                <div class="one-top">
                    <div>{{$t('Google身份验证器')}}</div>
                    <div><img src="../../assets/pictures/google@2x.png"></div>
                </div>
                <div style="display: flex;justify-content: center;flex-direction: column">
                    <div style="margin: 20px auto;">
                        <img src="../../assets/pictures/icon_lock.png">
                    </div>
                    <div style="padding: 0 20px 20px 20px;color: #cccccc;font-size: 12px;text-align: center">
                        {{$t('Google身份验证器应用程序将保护您的账户安全')}}
                    </div>
                </div>
            </div>
            <div style="display: flex;justify-content: space-between;color: #ffffff;font-size:14px;">
                <div>{{$t('身份验证器')}}</div>
                <div>
                    <van-switch v-model="checked" size="20px" inactive-color="#cccccc" active-color="#0184fa" @change="change" :disabled="checked"/>
                </div>
            </div>
            <div v-if="checked" @click="show = true"
                style="display: flex;justify-content: space-between;margin-top: 10px;background-color: #ffffff;padding: 10px;border-radius: 10px"
            >
                <div style="color: #0184fa;font-size: 14px;font-weight: bold">{{$t('解除绑定')}}</div>
                <van-icon name="arrow" color="#0184fa" />
            </div>
        </div>
        <van-popup class="code-page-popup" v-model="show" position="center" style="width: 90%;border-radius: 10px">
            <div class="bs-panel" style="padding: 20px">
                <div style="padding-bottom: 20px;font-size: 14px;">{{$t('您确定要解除Google身份验证器绑定吗？')}}</div>
                <div style="display: flex;justify-content: space-between;">
                    <van-button type="orange" @click="show = false" style="width: 48%;border-radius: 10px">{{$t('取消')}}</van-button>
                    <van-button type="warning" @click="url('/code-set-unbind')" style="width: 48%;border-radius: 10px">{{$t('确认')}}</van-button>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
    export default {
        components: {},
        data() {
            return {
                checked: false,
                show: false,
                password: '',
                old_password: '',
                new_password: '',
                q_password: '',
                withdraw_info: {},
            }
        },
        methods: {
            onClickLeft() {
                this.url('/my')
            },
            url(url) {
                this.$router.push({
                    path: url
                })
            },
            change(){
                if(this.checked == true){
                    this.url('/code-set-one');
                }
            },
            isBind(){
                this.$axios.get('/gcode/is_bind').then((res) => {
                    if (res.data.code == 0) {
                        this.checked = res.data.data.is_bind ? true : false;
                    }
                }).catch((err) => {
                    this.$toast.fail(err.message);
                });
            }
        },
        mounted() {
            localStorage.setItem('aqzx', '1');
        },
        created() {
            this.isBind();
        }
    }
</script>
<style scoped>
    .one-card{
        padding: 20px;
        margin-bottom: 20px;
        font-size: 14px;
    }
    .one-top{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .one-top img{
        width: 20px;
    }

</style>
